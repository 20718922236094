//TODO update on each release change (QA and PROD)
// let ENV = 'dev'
//let ENV = 'qa'
//#region PROD
let ENV = ''
//#endregion
let BASE_URL = `https://caretakerapi${ENV}.ecco2.ch/api/v1/`
//let BASE_URL =`http://localhost:44371/api/v1/`

let QA_TRACKING_ID = 'G-SRX9ECEHDC'
let PROD_TRACKING_ID = 'G-7Y5PV9ZLHF'
let TRACKING_ID = `${
  ENV === 'qa'
    ? QA_TRACKING_ID
    : ENV === 'dev'
    ? QA_TRACKING_ID
    : PROD_TRACKING_ID
}`

module.exports = {
  BASE_URL,
  TRACKING_ID,
}
